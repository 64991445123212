import { ApiService } from './services/api.service';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public appPages = [
    {
      title: 'Profiles',
      url: '/profiles',
      icon: 'newspaper',
      auth: true
    },
    {
      title: 'Events',
      url: '/events',
      icon: 'list-outline',
      auth: false
    },
    {
      title: 'Logout',
      url: '/login',
      icon: 'person-circle',
      auth: false
    }
  ];

  public appFilteredPages = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private api: ApiService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.api.getCurrentUser().subscribe(  user => {
        // console.log('User: ', user);
        if (user) {
          this.appFilteredPages = this.appPages.slice();
        } else {
          this.appFilteredPages = this.appPages.filter( menuItem => menuItem.auth === false);
        }
      });
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

}
