import { map, switchMap, tap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {
  user;

  constructor(
    private api: ApiService,
    private platform: Platform,
    private router: Router
    ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.api.userIsAuthenticated().pipe(
      map( res => {
        if (res === true) {
          return true;
        } else {
          this.router.navigateByUrl('/login');
          return false;
        }
      })
    );

  }

}
