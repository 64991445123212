import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, from, zip, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, switchMap, tap } from 'rxjs/operators';
import { tokenReference } from '@angular/compiler';

const JWT_KEY = 'mytoken';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private user = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private plt: Platform
    ) {
      this.plt.ready().then( () => {
        this.storage.get(JWT_KEY).then(  data => {
          if (data) {
            this.user.next(data);
          }
        });
      });
     }

  getProfiles(): Observable<any> {

    return this.http.get<any[]>(`${environment.apiUrl}/profiles?_embed`).pipe(
      map( res => {
        const profiles = res.map( elem => {
          return {
            id: elem.id,
            userId: elem.author,
            title: elem.title.rendered,
            content: elem.content.rendered,
            needs: elem.cmb2.sf_profile_metabox.sf_profile_needs,
            offers: elem.cmb2.sf_profile_metabox.sf_profile_offers,
            web: elem.cmb2.sf_profile_metabox.sf_profile_web,
            social: elem.cmb2.sf_profile_metabox.sf_profile_social,
            photoSrc: elem.cmb2.sf_profile_metabox.sf_profile_photo
          };
        });
        return profiles.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));

      })
    );
  }

  signIn(username, password) {
    return this.http.post(`${environment.authUrl}/jwt-auth/v1/token`, {username, password}).pipe(
      switchMap(  token => {
        const nicename = token['user_nicename'];
        const author = this.http.get<any[]>(`${environment.apiUrl}/author/${nicename}`);
        return zip( of(token), author);
      }),
      switchMap( data => {
        // const [token, author] = data;
        const token = data[0];
        token['ID'] = data[1]['ID'];

        return from(this.storage.set(JWT_KEY, token));
      }),
      tap( ( data ) => {
        this.user.next(data);
      })
    );
  }

  getCurrentUser() {
    return this.user.asObservable();
  }

  getUserValue() {
    return this.user.getValue();
  }

  userIsAuthenticated() {
    return this.user.asObservable().pipe(
      map( user => {
        if (user) {
          return true;
        } else{
          return false;
        }
      })
    );
  }

  logout() {
    this.storage.remove(JWT_KEY).then( () => {
      this.user.next(null);
    });
  }
}
